<template>
  <div class="wrapper">
    <div class="content">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="订单号">{{ detail.order_sn }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">
          <el-tag v-if="detail.storage_status == 1" type="warning" size="mini">{{ detail.storage_status_dsc }}</el-tag>
          <el-tag v-if="detail.storage_status == 10" type="danger" size="mini">{{ detail.storage_status_dsc }}</el-tag>
          <el-tag v-if="detail.storage_status == 20" type="primary" size="mini">{{ detail.storage_status_dsc }}</el-tag>
          <el-tag v-if="detail.storage_status == 30" type="info" size="mini">{{ detail.storage_status_dsc }}</el-tag>
          <el-tag v-if="detail.storage_status == 40" type="success" size="mini">{{ detail.storage_status_dsc }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="大件数量"> {{ detail.large_luggage_quantity }} </el-descriptions-item>
        <el-descriptions-item label="小件数量"> {{ detail.small_luggage_quantity }} </el-descriptions-item>
        <el-descriptions-item label="支付金额">{{ detail.pay_money }}</el-descriptions-item>
        <el-descriptions-item label="商户收益">{{ detail.store_money }}</el-descriptions-item>
        <el-descriptions-item label="平台收益">{{ detail.platform_money }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{ detail.pay_method_dsc }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ detail.created_at }}</el-descriptions-item>
        <el-descriptions-item label="寄存时间"> {{ detail.start_time }} 至 {{ detail.end_time }} </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="tabs-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户信息" name="user">
          <el-descriptions>
            <el-descriptions-item label="存件人姓名">{{ detail.name }}</el-descriptions-item>
            <el-descriptions-item label="存件人手机号">{{ detail.phone }}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="寄存点信息" name="store">
          <el-descriptions v-if="detail.store">
            <el-descriptions-item label="名称">{{ detail.store.store_title }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{ detail.store.contact }}</el-descriptions-item>
            <el-descriptions-item label="地址">{{ detail.store.address }}</el-descriptions-item>
            <el-descriptions-item label="标签">
              <el-tag style="margin-right: 5px" type="primary" size="mini" v-for="(item, i) in detail.store.tags" :key="i">{{ item }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="评价">
              <el-rate :max="5" disabled allow-half :value="detail.store.star_num / 2"></el-rate>
            </el-descriptions-item>
            <el-descriptions-item label="工作日">{{ detail.store.work_day }}</el-descriptions-item>

            <el-descriptions-item label="照片">
              <el-image
                class="cover"
                style="width: 80px; height: 80px; border-radius: 4px; display: block"
                :src="detail.store.img"
                :preview-src-list="[detail.store.img]"
                fit="cover"
              >
                <div slot="error" style="line-height: 80px; text-align: center; background: #f5f7fa">无</div>
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="行李照片" name="photo">
          <div class="photo-box">
            <el-image
              v-for="(item, i) in detail.luggage_photos"
              :key="i"
              class="img"
              style="width: 200px; height: 200px"
              :src="item"
              :preview-src-list="[item]"
              fit="cover"
            >
              <div style="line-height: 200px; text-align: center; background: #f5f5f5" slot="error">无</div>
            </el-image>
          </div>
          <el-empty v-if="detail.luggage_photos.length == 0" description="无"></el-empty>
        </el-tab-pane>
       
      </el-tabs>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
   
  },
  data() {
    return {
      activeName: 'user',
  
    }
  },

  mounted() {},

  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .userinfo {
    display: flex;
    padding: 0 20px;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }
  }
  .content {
    padding: 20px;
  }
  .tabs-box {
    padding: 0 20px;
  }
  .photo-box {
    display: flex;
    .img {
      margin-right: 10px;
      width: 100px;
      height: 100px;
      display: block;
    }
  }
}
</style>
