<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="search" ref="form" inline :model="searchData" label-width="">
      <el-form-item label="订单号">
        <el-input style="width: 220px" clearable v-model.trim="searchData.order_sn" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商户">
        <SelectMerchant v-model="searchData.store_id"></SelectMerchant>
      </el-form-item>
      <el-form-item label="下单手机号">
        <el-input style="width: 220px" clearable v-model.trim="searchData.phone" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="searchData.timeSlot"
          style="width: 220px"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          range-separator="-"
          size="mini"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="完成时间">
        <el-date-picker
          v-model="searchData.finish_timeSlot"
          style="width: 220px"
          value-format="yyyy-MM-dd "
          type="daterange"
          range-separator="-"
          size="mini"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.storage_status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.storage_status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">待支付</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.storage_status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">待寄存</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.storage_status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">寄存中</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.storage_status === 30 ? 'active' : '']" @click="handleClickTab(30)">
        <el-badge>
          <span class="tab-name">已取消</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.storage_status === 40 ? 'active' : '']" @click="handleClickTab(40)">
        <el-badge>
          <span class="tab-name">已完成</span>
        </el-badge>
      </div>
    </div>
    <el-button style="margin-bottom: 20px" icon="el-icon-download" type="primary" size="mini" plain @click="exportTable">导出</el-button>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="用户信息" min-width="180">
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <span>ID：</span>
              <el-link type="primary" @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })">{{ row.user.id }}</el-link>
            </div>
            <div class="row">
              <span>昵称：</span>
              <span>{{ row.user.nickname }}</span>
            </div>
            <div class="row">
              <span>手机号：</span>
              <span>{{ row.user.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单编号" min-width="150"></el-table-column>
      <el-table-column prop="store_title" label="商户" min-width="150">
        <template slot-scope="{ row }">
          {{ row.store.store_title }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.storage_status == 1" type="warning" size="mini">{{ row.storage_status_dsc }}</el-tag>
          <el-tag v-if="row.storage_status == 10" type="danger" size="mini">{{ row.storage_status_dsc }}</el-tag>
          <el-tag v-if="row.storage_status == 20" type="primary" size="mini">{{ row.storage_status_dsc }}</el-tag>
          <el-tag v-if="row.storage_status == 30" type="info" size="mini">{{ row.storage_status_dsc }}</el-tag>
          <el-tag v-if="row.storage_status == 40" type="success" size="mini">{{ row.storage_status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="pay_money" label="金额" min-width="150">
        <template slot-scope="{ row }">
          <div>订单金额：{{ Number(row.pay_money) + Number(row.coupon_money) }}</div>
          <div>优惠金额：-{{ row.coupon_money }}</div>
          <div>支付金额：{{ row.pay_money }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="store_money" label="商户收益" min-width="100"></el-table-column>
      <el-table-column prop="platform_money" label="平台收益" min-width="100"></el-table-column>
      <el-table-column prop="pay_method_dsc" label="支付方式" min-width="100"></el-table-column>
      <el-table-column prop="large_luggage_quantity" label="大件数量" min-width="100"></el-table-column>
      <el-table-column prop="small_luggage_quantity" label="小件数量" min-width="100"></el-table-column>

      <el-table-column prop="" label="寄存时间" min-width="170">
        <template slot-scope="{ row }">
          <div>
            <div class="row">
              <span>开始时间：</span>
              <span>{{ row.start_time }}</span>
            </div>
            <div class="row">
              <span>结束时间：</span>
              <span>{{ row.end_time }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="created_at" label="下单时间" min-width="170"></el-table-column>
      <el-table-column prop="finish_time" label="完成时间" min-width="170"></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
      <el-table-column prop="id" label="操作" width="160" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情 </el-link>
          <el-link v-if="searchData.storage_status == 1" style="margin-right: 10px" type="warning" :underline="false" @click="handlePayStatus(row)">手动支付</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 详情弹窗 -->
    <el-drawer size="55%" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail"></Detail>
    </el-drawer>
    <div style="height: 50px"></div>
    <!-- 手动支付弹窗 -->
    <el-dialog title="手动支付" :visible.sync="show_pay" width="width">
      <el-form label-width="120px" label-suffix="：" @submit.native.prevent>
        <el-form-item label="备注">
          <el-input style="width: 400px" type="textarea" v-model="pay_data.remark" rows="5" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_pay = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmPay" size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, getDetailAPI, DownloadAPI, delAPI, payStatusAPI } from './api'
import Detail from './detail.vue'
import SelectMerchant from '@/views/merchant/merchant-list/select-merchant-list.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail, SelectMerchant },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        phone: '',
        timeSlot: [],
        finish_timeSlot: [],
        created_at_start: '',
        created_at_end: '',
        order_sn: '',
        store_id: '',
        storage_status: 0
      },
      total: 0,
      detail: {},
      show_pay: false,
      pay_data: {
        id: '',
        remark: ''
      }
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {
    // this.getList()
  },

  methods: {
    handlePayStatus({ id }) {
      this.pay_data = {
        id,
        remark: ''
      }
      this.show_pay = true
    },
    confirmPay() {
      payStatusAPI(this.pay_data).then((res) => {
        this.$message.success('操作成功')
        this.show_pay = false
        this.getList()
      })
    },
    exportTable() {
      const { page, page_size, phone, order_sn, timeSlot, finish_timeSlot, store_id, storage_status } = this.searchData
      let params = { page, page_size, phone, order_sn, store_id }
      if (timeSlot && timeSlot.length > 0) {
        params.last_end_time_start = timeSlot[0]
        params.last_end_time_end = timeSlot[1]
      }
      if (finish_timeSlot && finish_timeSlot.length > 0) {
        params.finish_day_start = finish_timeSlot[0]
        params.finish_day_end = finish_timeSlot[1]
      }
      if (storage_status > 0) {
        params.storage_status = storage_status
      }
      DownloadAPI(params)
    },
    async getList() {
      const { page, page_size, phone, order_sn, timeSlot, finish_timeSlot, store_id, storage_status } = this.searchData
      let params = { page, page_size, phone, order_sn, store_id }
      if (timeSlot && timeSlot.length > 0) {
        params.last_end_time_start = timeSlot[0]
        params.last_end_time_end = timeSlot[1]
      }
      if (finish_timeSlot && finish_timeSlot.length > 0) {
        params.finish_day_start = finish_timeSlot[0]
        params.finish_day_end = finish_timeSlot[1]
      }
      if (storage_status > 0) {
        params.storage_status = storage_status
      }
      const res = await getListAPI(params)
      this.list = res.data
      this.total = res.total
    },
    // 详情
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.storage_status == i) return
      this.searchData.storage_status = i
      this.getList()
    },
    handleDel({ id }) {
      this.$confirm('确认删除该订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .search {
    .el-form-item {
      margin-bottom: 0px;
      margin-right: 15px;
    }
  }
  .user-box {
    .row {
      display: flex;
      span {
        &:nth-child(1) {
          width: 60px;
          text-align: right;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
